export const licenceType = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "M", value: "M" },
    { label: "E", value: "E" },
];

export const roles = [
    { label: "Administrador", value: 1 },
    { label: "Comandante", value: 5 },
    { label: "Jefe de Servicio", value:  7},
    { label: "Cabinista", value: 8 },
    { label: "Bombero", value: 10 },
    { label: "Recursos Humanos", value: 11 },
];

export const STATION_STATUS = [
    { label: "Disponible", value: 10 },
    { label: "Activo", value: 20 },
    { label: "De vuelta", value: 30 },
    { label: "En emergencia", value: 40 },
    { label: "Inactivo", value: 50 },
];

export const STATION_STATUS_IN_LIST = [
    { label: "Todos", value: null },
    { label: "Disponible", value: 10 },
    { label: "Activo", value: 20 },
    { label: "De vuelta", value: 30 },
    { label: "En emergencia", value: 40 },
    { label: "Inactivo", value: 50 },
];

export const VEHICLE_STATUS = [
    { label: "Disponible", value: 10 },
    { label: "En emergencia", value: 40 },
    { label: "No disponible", value: 50 },
    { label: "En reparación", value: 60 },
];

export const VEHICLE_STATUS_CREATE = [
    { label: "Disponible", value: 10 },
    { label: "No disponible", value: 50 },
    { label: "En reparación", value: 60 },
];

export const USER_RANGES = [
    { label: "Bombero de 3ra. Clase", value: 3 },
    { label: "Bombero de 2da. Clase", value: 1 },
    { label: "Bombero de 1ra. Clase", value: 2 },
    { label: "Suboficial de 3ra. Clase", value: 10 },
    { label: "Suboficial de 2da. Clase", value: 11 },
    { label: "Suboficial de 1ra. Clase", value: 12 },
    { label: "Oficial de 3ra. Clase", value: 20 },
    { label: "Oficial de 2da. Clase", value: 21 },
    { label: "Oficial de 1ra. Clase", value: 22 },
    { label: "Oficial Mayor de 3ra. Clase", value: 30 },
    { label: "Oficial Mayor de 2da. Clase", value: 31 },
    { label: "Oficial Mayor de 1ra", value: 32 },
];

export const NOTIFICATIONS_TYPE = [
    { label: "Cabina", value: "Cabina" },
    { label: "Presencial", value: "Presencial" },
    { label: "Teléfono", value: "Teléfono" },
];

export const GENDER = [
    { label: "Femenino", value: 10 },
    { label: "Masculino", value: 1 },
];

// ROLES
export const ROLE_ADMIN = 1 // Administrador
export const ROLE_ADMIN_STATION = 5 // Comandante
export const ROLE_STATION_BOSS = 7 // Jefe de Servicio
export const ROLE_CABIN_ATTENDANT = 8 // Cabinista
export const ROLE_FIREFIGHTER = 10 // Bombero
export const HUMAN_RESOURCES = 11 // Recursos Humanos

//export
export const STATUS_EMERGENCY = [
    { label: "Todos", value: null },
    { label: "Activo", value: 20 },
    { label: "Resuelta", value: 30 },
    { label: "Critica", value: 50 },
    { label: "Solicita apoyo", value: 40 },
];

export const STATUS_EMERGENCY_TRACKING = [
    { label: "Todos", value: null },
    { label: "Activo", value: 20 },
    { label: "Critica", value: 50 },
    { label: "Solicita apoyo", value: 40 },
    { label: "Disponible", value:  60},
];

export const STATUS_EMERGENCY_LIST = [
    { label: "Activo", value: 20 },
    { label: "Resuelta", value: 30 },
    { label: "Critica", value: 50 },
    { label: "Solicita apoyo", value: 40 },
];

//export
export const REGION_TYPE = [
    { label: "Central", value: "Central" },
    { label: "Sur", value: "Sur" },
    { label: "Oriente", value: "Oriente" },
    { label: "Occidente", value: "Occidente" },
    { label: "Norte", value: "Norte" },
];

//export

export const FIREFIGHTER_TYPE = [
    { label: "Permanente", value: "Permanente" },
    { label: "Ad Honorem", value: "Ad honorem" },
];
export const REGION_TYPE_IN_LIST = [
    { label: "Todos", value: null },
    { label: "Central", value: "Central" },
    { label: "Sur", value: "Sur" },
    { label: "Oriente", value: "Oriente" },
    { label: "Occidente", value: "Occidente" },
    { label: "Norte", value: "Norte" },
];

// Action binnacle
export const ACTION_BINNACLE_IN_LIST = [
    { label: "Todos", value: null },
    { label: "CREAR", value: "CREAR" },
    { label: "EDITAR", value: "EDITAR" },
    { label: "ELIMINAR", value: "ELIMINAR" },
    { label: "LOGIN", value: "LOGIN" },
];

export const INACTIVE_STATUS = [
    { label: "Suspendido", value: 1 },
    { label: "Vacaciones", value: 2 },
    { label: "Inactivo", value: 3 },
    { label: "Despedido", value: 4 },
];

export const INACTIVE_STATUS_FILTER = [
    { label: "Todos", value: null },
    { label: "Activo", value: 5 },
    { label: "Suspendido", value: 1 },
    { label: "Vacaciones", value: 2 },
    { label: "Inactivo", value: 3 },
    { label: "Despedido", value: 4 },
];

export const USER_STATUS = [
    { label: "Activo", value: 5 },
    { label: "Inactivo", value: 1 },
];

export const CONTRACT = [
    { label: "Municipalidad", value: 1 },
    { label: "Comité", value: 2 },
];

export const ON_TRACKING = [
    { label: "En trackeo", value: true },
    { label: "Sin trackear", value: false },
];
