import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderField, renderNumber } from "../../Utils/renderField";
import { renderFieldRadio, renderMap } from "../../Utils/renderField/renderField";
import { validate, validators } from "validate-redux-form";
import { SelectField } from "../../Utils/renderField/renderField";
import { STATION_STATUS, REGION_TYPE } from "../../../../utility/constants";
import { validateNumber, validateWhiteSpace } from "../../../../utility/validation";
// const  { DOM: { input, select, textarea } } = React

const RegisterForm = (props) => {
    const {
        handleSubmit,
        isUpdate,
        isView,
        mark_lng,
        mark_lat,
        radio,
        setRadio,
    } = props;

    return (
        <form name="loginForm" onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="first_name">Nombre</label>
                        <Field
                            type="text"
                            name="name"
                            label="name"
                            disabled={isView}
                            component={renderField}
                            validate={validateWhiteSpace}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="last_name">No. Estación</label>
                        <Field
                            type="number"
                            name="no_station"
                            disabled={isView}
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Teléfono</label>
                        <Field
                            type="text"
                            disabled={isView}
                            numberFormat={"+(502) ####-####"}
                            name="phone_number"
                            label="phone_number"
                            component={renderNumber}
                            validate={validateNumber}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Estado</label>
                        <Field
                            name="state"
                            label="state"
                            disabled={isView}
                            options={STATION_STATUS}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="last_name">Dirección</label>
                        <Field
                            type="text"
                            name="address"
                            label="address"
                            disabled={isView}
                            component={renderField}
                            validate={validateWhiteSpace}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="direction">Región</label>
                        <Field
                            name="region_type"
                            label="region_type"
                            placeholder={isView ? "Sin region" : "Region"}
                            disabled={isView}
                            options={REGION_TYPE}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                    {!isView && (
                        <React.Fragment>
                            <label htmlFor="latlng">Latitud y longitud</label>
                            <div className="d-flex">
                                <label className="negro font-weight-normal">
                                    <Field
                                        style={{ width: 30 }}
                                        name="radio_val"
                                        component="input"
                                        type="radio"
                                        value="map"
                                        checked={radio === "map"}
                                        onClick={() => setRadio("map")}
                                    />
                                    <span>Ingresar por mapa</span>
                                </label>
                                <label className="negro font-weight-normal">
                                    <Field
                                        style={{ width: 30 }}
                                        name="radio_val"
                                        component="input"
                                        type="radio"
                                        value="text"
                                        checked={radio === "text"}
                                        onClick={() => setRadio("text")}
                                    />
                                    <span>Ingresar manualmente</span>
                                </label>
                            </div>
                        </React.Fragment>
                    )}
                    {isView ? (
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="direction">Latitud</label>
                                <Field
                                    type="number"
                                    name="lat"
                                    label="address"
                                    disabled={isView}
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="direction">Longitud</label>
                                <Field
                                    type="number"
                                    name="lng"
                                    label="address"
                                    disabled={isView}
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <Field
                                    name="latlng"
                                    label="latlng"
                                    disabled={isView}
                                    component={renderMap}
                                    mark_lat={mark_lat}
                                    mark_lng={mark_lng}
                                    className="form-control"
                                    heigth={"100%"}
                                    width={"100%"}
                                />
                            </div>
                        </React.Fragment>
                    ) : radio == "map" ? (
                        <div className="form-group">
                            <Field
                                name="latlng"
                                label="latlng"
                                disabled={isView}
                                component={renderMap}
                                mark_lat={mark_lat}
                                mark_lng={mark_lng}
                                className="form-control"
                                heigth={"100%"}
                                width={"100%"}
                            />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="direction">Latitud</label>
                                <Field
                                    type="number"
                                    name="lat"
                                    label="address"
                                    disabled={isView}
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="direction">Longitud</label>
                                <Field
                                    type="number"
                                    name="lng"
                                    label="address"
                                    disabled={isView}
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3"></div>
                    <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                        <Link
                            to={"/stations"}
                            className={
                                isView
                                    ? "btn btn-secondary px-3 py-3 mt-3 mb-3 w-100"
                                    : "btn btn-danger py-3 mt-3 mb-3 w-100"
                            }
                        >
                            {isView ? "Atras" : "Cancelar"}
                        </Link>
                        {!isView && (
                            <button
                                type="submit"
                                className="btn btn-success align-self-center py-3 m-3 font-weight-bold w-100"
                            >
                                {isUpdate ? "Actualizar" : "Crear"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "StationForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()("Este campo es requerido"),
            state: validators.exists()("Este campo es requerido"),
            address: validators.exists()("Este campo es requerido"),
            phone_number: validators.exists()("Este campo es requerido"),
            latlng: validators.exists()("Este campo es requerido"),
            no_station: validators.exists()("Este campo es requerido"),
            lng: validators.exists()("Este campo es requerido"),
            lat: validators.exists()("Este campo es requerido"),
            region_type: validators.exists()("Este campo es requerido"),
        });
    },
})(RegisterForm);
